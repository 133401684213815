import { Link } from "@mui/material";
import Send from "../assets/img/telegram.svg";
import Email from "../assets/img/email.png";

const Footer = () => {
  return (
    <div className="bg-darkBluish py-2 mt-8">
      <div className="mx-auto max-w-[1140px] px-4">
        <div className="flex justify-end items-center gap-2 text-white">
          <p>Join our community:</p>
          
          {/* Telegram Link */}
          <div className="p-1 bg-telegramBlue rounded-full flex justify-center items-center">
            <Link
              href="https://t.me/sbff_open"
              target="_blank"
              className="text-white"
            >
              <img src={Send} alt="Telegram" className="text-white h-6 w-6" />
            </Link>
          </div>

          {/* Email Link */}
          <div className="p-1 bg-telegramBlue rounded-full flex justify-center items-center">
            <Link
              href="mailto:freedomx.inquiries@gmail.com"
              className="text-white"
            >
              <img src={Email} alt="Email" className="text-white h-6 w-6" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;